<template>
  <NuxtLink :to="url" :class="getClasses">
    <slot /> <span v-if="arrow">→</span>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'default'
  },
  arrow: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 1
  }
})

const getClasses = computed(() => {
  let classes= ''
  if (props.type === 'primary') classes += 'rounded-md transition bg-[#8293fa] border-2 border-[#5a6ded] hover:bg-[#5a6ded] px-3.5 py-2.5 text-sm font-semibold text-white focus:outline-none'
  if (props.type === 'ghost' || props.type === 'secondary') classes += 'rounded-md transition bg-transparent hover:bg-[rgba(0,0,0,0.03)] px-3.5 py-2.5 text-sm font-semibold text-[#5a6ded] focus:outline-none'
  if (props.type === 'black') classes += 'rounded-md border-2 border-black px-3.5 py-2.5 text-sm font-semibold text-black font-semibold focus:outline-none hover:bg-[rgba(0,0,0,0.03)]'
  return classes
})

</script>